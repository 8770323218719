import React from 'react'
import image1 from "../img/image1.png"
import image2 from "../img/image2.png"
import image3 from "../img/image3.png"
import image4 from "../img/image4.png"
import velkambu from "../img/velkambupic.JPG"
import valpic from "../img/valpic.JPG"
import ek from "../img/erattaikambu.JPG"
import kuthuvarisai from "../img/kuthuvarisai.JPG"
import silambamInd from "../img/silambamIndia.JPG"
import kuthuvarisai2 from "../img/kuthuvarisai2.JPG"
import surul1 from "../img/surul1.JPG"
import errataisurul from "../img/errataisurul.JPG"
// import nb from "../img/nb.png"
// import adidas from "../assets/adidas.png"
// import nike from "../assets/nike.png"
import tick from '../img/tick.png'
import './Reasons.css'
const Reasons = () => {
  return (
    <div className="Reasons" id="reasons">
        <div className="left-r">
           <img src={valpic} alt="" />
           {/* <img src={} alt="" /> */}
           <img src={ek} alt="" />
           {/* <img src={silambamInd} alt="" /> */}
           <img src={velkambu} alt="" />
        </div>

        <div className="right-r">
            <span>Benifites And</span>
            <div>
                <span className='stroke-text'>Why</span>
                <span> Choose us</span>
            </div>

            <div className='details-r'>
            <div>
                <img src={tick} alt=""></img>
                <span className='r-txt'> focus on Self defence - fitness - flexibility - Body Awareness and Posture - physical Endurance </span>
            </div>
            <div>
                <img src={tick} alt=""></img>
                <span className='r-txt'>Conducting the Belt grading </span>
            </div>
            <div>
                <img src={tick} alt="" />
                <span className='r-txt'>More focus on Imporoving Students</span>
            </div>
            <div>
                <img src={tick} alt="" />
                <span className='r-txt'>Having A professional trainers</span>
            </div>
            <div>
                <img src={tick} alt="" />
                <span className='r-txt'> try to Make every student as a coach</span>
            </div>
            
            {/* <span style={{color:"var(--gray)",fontWeight:"normal",fontSize:'1rem'}}>
                Our Partners
            </span> */}
            {/* <div className="partners">
                <img src={nb} alt="" />
                <img src={adidas} alt="" />
                <img src={nike} alt="" />
            </div> */}
            </div>
        </div>
    </div>
  )
}

export default Reasons