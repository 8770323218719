import React, { useState } from 'react'
import {AchievementData} from '../Achievements/AchievementsData'
import leftArrow from '../img/leftArrow.png'
import rightArrow from '../img/rightArrow.png'
import {motion} from 'framer-motion'
import './Achievements.css'
const Testimonials = () => {
   const transition={type:"sprin",duration:1};
   const [selected ,setselected]=useState(0);
   const tLength=AchievementData.length;
  return (
    <div className="Achievements">
       <div className="left-t">
           <span>TEAM ACHIEVEMENTS</span>
           <span className='stroke-text'>People With</span>
           <span>Great Passion</span>

           <motion.span
           key={selected}
           initial={{ opacity : 0, x :-100}}
           animate={{opacity : 1, x : 0}}
           exit={{ opacity : 0,x : 100}}
           transition={transition}
           >{AchievementData[selected].review}
           </motion.span>
           <span>
               <span style={{color:"var(--orange)"}}>
                   {AchievementData[selected].name}
               </span>{" "}
               -{" "} {AchievementData[selected].status }
           </span>
       </div>

       <div className="right-t">
           <motion.div
              initial={{opacity: 0 ,x: -100}}
              transition={{ ...transition , duration: 2}}
              whileInView={{opacity: 1 , x: 0}}
           >
           </motion.div>
           <motion.div
               initial={{opacity:0 ,x:100}}
              transition={{ ...transition ,duration:2}}
           whileInView={{opacity:1 ,x:0}}
           >
           </motion.div> 
           <motion.img
           key={selected}
           initial={{opacity:0,x:100}}
           animate={{opacity:1,x:0}}
           exit={{opacity:0,x:-100}}
           transition={transition}
           src={AchievementData[selected].image} alt="" />
           <div className="arrows">
               <img 
               onClick={()=>{
                   selected===0?setselected(tLength-1):setselected((pre)=>(pre-1))
               }}
               src={leftArrow}  alt="" />

               <img
               onClick={()=>{
                   selected===tLength-1?setselected(0):setselected((cur)=>cur+1)
               }}
               src={rightArrow} alt="" />
           </div>
       </div>
    </div>
  )
}

export default Testimonials