import React from 'react'
import Header from './Header'
import Achievements from '../Achievements/Achievements'
import Reasons from '../Why_Us/Reasons'
import './Hero.css'
import {Link} from "react-scroll"
import hero_image_back from "../img/hero_image_back.png";
import hero_image from "../img/hero_image.png";
import NumberCounter from 'number-counter'
import {motion} from 'framer-motion'
const transition={type:'spring',duration:3}
const mobile=window.innerWidth<=768? true: false;
const Hero = () => {
  return (
    <div className="hero" id='home'>
      <div className="blur hero-blur"></div>
      <div className="left-h">
        <div className='headerr'>
        <Header/>
        </div>
        
        <div className="the-best-add">
          
        <motion.div
                initial={{left:mobile? "130px" : '140px'}}
                whileInView={{left:'8px'}}
                transition={{...transition,type:'tween'}}
        ></motion.div>
            
        <span>Silambam-Adimurai-Parai-Artist</span>
        </div>
         {/* the Hero Heading */}
         <div className="hero-text">
            <div>
                <span className='stroke-text'>Shape </span>
                <span>Your</span>
            </div>
            <div><span>Mind - Body</span></div>
            <div>
               <span>Passionate coach fostering discipline, Inspiring students, preserving traditions, pushing boundaries for success</span>
            </div>
         </div>
         {/* Figures */}
         <div className="figures">
      <div>
        <span>
          {/* +250 */}
          <NumberCounter end={2500} start={1000} delay='4' preFix="+"></NumberCounter>
        </span>
        <span>Students</span>
      </div>

      <div>
        <span>
          {/* +120 */}
        <NumberCounter end={120} start={0} delay='4' preFix="+"></NumberCounter>
        </span>
        <span>Team_Medals</span>
      </div>
      
      <div>
        <span>
          {/* +20 */}
        <NumberCounter end={55} start={10} delay='4' preFix="+"></NumberCounter>
        </span>
        <span>Classes</span>
      </div>
      
     </div>
      {/* Hero Buttons */}
      <div className="hero-buttons">
              <button className="btn"> <Link
                 to="Classes"
                 span={true}
                 smooth={true}
              >Classes</Link></button>
              <button className="btn"><Link
                className='contact-btn'
                 to="contact"
                 span={true}
                 smooth={true}
              >Contact</Link></button>
             
           </div>
      </div>

      <div className="right-h">
         <button className="btn"><Link
                className='contact-btn'
                 to="contact"
                 span={true}
                 smooth={true}
              >Get More</Link></button>
         <img src={hero_image} alt="" className='hero-image' />
         <img src={hero_image_back} alt="" className='hero_back_img' />
         <hr />

      </div>
{/* 
      <br />
      <br />





      {/* Achievements */}
      {/* <Achievements/> */}

     {/* <br /> */}
     {/* <br /> */}
      {/* Reasons */}
      {/* <Reasons/> */} 



    </div>




      
  
  )
}

export default Hero