import './App.css';
import Header from './Hero/Header';
import Hero from './Hero/Hero';
import About from './About/About';
import Achievements from './Achievements/Achievements';
import Reasons from './Why_Us/Reasons';
import Classes from './Classes/Classes';
import Contact from './Contact/Contact';
import Gallary from './Gallary/Gallary';
function App() {
  return (
    <div className="App">
       <Hero/> 
       <Gallary/>
       <Achievements/>
       <Reasons/>
       <Classes/>
       <About/>
       <Contact/>
   
    </div>
  );
}

export default App;
