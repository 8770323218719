import React, { useRef, useEffect, useState } from "react";
import { GallaryData } from "../Gallary/GallaryData";
import leftArrow from "../img/leftArrow.png";
import rightArrow from "../img/rightArrow.png";
import { motion } from "framer-motion";
import "./Gallary.css";

const Gallary = () => {
  const timerRef = useRef(null);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    console.log("UseEffect");
    timerRef.current = setTimeout(() => {
      selected === tLength - 1 ? setselected(0) : setselected((cur) => cur + 1);
    }, 3000);

    return () => clearTimeout(timerRef.current);
  }, );

  const transition = { type: "sprin", duration: 1 };
  const [selected, setselected] = useState(0);
  const tLength = GallaryData.length;
  return (
    <div className="Gallary">
      <div className="left-t">
        <span>Images</span>
        {/* <span className='stroke-text'></span> */}
        <span style={{ "font-size": "1.2rem" }}>Silambam | Adimurai</span>
        {/* <br /> */}
        {/* <motion.span
            key={selected}
            initial={{ opacity : 0, x :-100}}
            animate={{opacity : 1, x : 0}}
            exit={{ opacity : 0,x : 100}}
            transition={transition}
            >{GallaryData[selected].review}
            </motion.span> */}
        {/* <span>
                <span style={{color:"var(--orange)"}}>
                    {GallaryData[selected].name}
                </span>{" "}
                -{" "} {GallaryData[selected].status }
            </span> */}
      </div>

      <div className="right-t">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          transition={{ ...transition, duration: 2 }}
          whileInView={{ opacity: 1, x: 0 }}
        ></motion.div>
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          transition={{ ...transition, duration: 2 }}
          whileInView={{ opacity: 1, x: 0 }}
        ></motion.div>
        <motion.img
          key={selected}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={transition}
          src={GallaryData[selected].image}
          alt=""
        />
        <div className="arrows">
          <img
            onClick={() => {
              selected === 0
                ? setselected(tLength - 1)
                : setselected((pre) => pre - 1);
            }}
            src={leftArrow}
            alt=""
          />

          <img
            onClick={() => {
              selected === tLength - 1
                ? setselected(0)
                : setselected((cur) => cur + 1);
            }}
            src={rightArrow}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Gallary;
