import React from 'react'
import home_address from "../img/home_address.png"
import mail from '../img/mail.png'
import phone  from '../img/phone_call.png'
import facebook from "../img/facebook.png"
import whatsapp from '../img/whatsapp.png'
import insta from "../img/instagram.png"
import youtube from "../img/youtube.png"
import './Contact.css'
const Contact = () => {
  return (
    <div className="contact">
      <h1>Contact</h1>
      <div className="address">
         <img src={home_address} className="icon-c" alt="" />
         <h5 >Madathukulam,Tirupppur District</h5>
         <span>Our Address</span>
      </div>
      <div className="mail">
        <img src={mail} className="icon-c" alt="" />
        <h5 >vinojo@gmail.com</h5>
         <span>Our Mailbox</span>
      </div>
      <div className="mobile">
         <img src={phone} className="icon-c" alt="" />
         {/* <i class="fa-solid fa-phone fa-beat"></i> */}
        <h5 >+91 9698328738</h5>
        <span>Contact</span>
       
      </div>
      <hr />
      <div className="social-media">         
        <div> <img src={facebook} alt="" /></div>
        <div> <img src={youtube} alt="" /></div>
        <div>  <img src={whatsapp} alt="" /></div>
        <div> <img src={insta} alt="" /></div>
      </div>
    </div>
  )
}

export default Contact