import React from 'react'
import './About.css'
import hero_img from '../img/hero_image.png'
import bullet from '../img/tick.png'
import vino from "../img/vino_uniform.png"
const About = () => {
  return (
     <div className="About">
        <div className="left-a">
          <img src={vino} alt="" />
        </div>
        <div className="right-a">
           <span>Short Description</span>
           <div className='about-h'>
                <span className='stroke-text'>About </span>
                <span> Vinoth</span>
            </div>
            <div className='about-content'>
              <p>Having 16 Years of Experience in Silambam and 9 years of Experience in Adimurai,Kalaripayattu and working as Silambam and Adimurai Coach</p>
              <span>Work Experience</span>
                <div>
                  <img src={bullet} alt="" />
                  &ensp;National Chief Coach and Referee Of All India Silambam Federation [AISF]
                </div>
                <div>
                <img src={bullet} alt="" />
                &ensp;National Chief Voach and Referee Of Verma  Adimurai Federation Of India
                </div>
                <div>
                <img src={bullet} alt="" />
                &ensp;General Secretary Of Tiruppur District Ameature Silambam Association[TDASA]
                </div>
                <div>
                <img src={bullet} alt="" />
                &ensp;Conduct [GO & NGO] MartialArts Programs ,Training Camp and Compatitions through Overall
                </div>
                <div>
                <img src={bullet} alt="" />
                &ensp;Traditional Parai Music and Dance Trainer
                </div>
                <div>
                <img src={bullet} alt="" />
                &ensp;Kalari MAssage(Kalari Uzhichal) Therapy
                </div><br />

                <span>Education</span>
                <div>
                  <img src={bullet} alt="" />
                  &ensp;PG Diploma In Silambam Dencing 
                </div>
                <div>
                <img src={bullet} alt="" />
                &ensp;Diploma In Varma Massage Therapy
                </div>
                <div>
                <img src={bullet} alt="" />
                &ensp;4th Dan Black Belt in Intternational Silambam Acemady
                </div>
                {/* <br /> */}
               
          </div>
        </div>
     </div>
  )
}

export default About