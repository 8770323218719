import image1 from "../img/bars.png";
import image2 from "../img/hero_image.png";
import image3 from "../img/vinoth-text.png";
import vino from "../img/hero_image.png";
import velkambu from "../img/velkambupic.JPG"
import valpic from "../img/valpic.JPG"
import ek from "../img/erattaikambu.JPG"
import kuthuvarisai from "../img/kuthuvarisai.JPG"
import silambamInd from "../img/silambamIndia.JPG"
import kuthuvarisai2 from "../img/kuthuvarisai2.JPG"
import surul1 from "../img/surul1.JPG"
import errataisurul from "../img/errataisurul.JPG"

export const AchievementData = [
  {
    image: vino,
    review:
      "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
    name: 'MATHEW HENDRICKSON',
    status : 'ENTREPRENEUR'
  },
  {
    image: kuthuvarisai,
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ipsam, ab itaque nam perferendis impedit sint ',
    name: 'JOHN KEVIN',
    status: 'COACH'
  },
  {
    image : surul1,
    review:' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem',
    name: 'FRANKLIN',
    status: "CUSTOMER"
  }
];
