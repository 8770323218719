import React from 'react'
import './Classes.css'
import {classesSlmbmData} from './ClassesSlmbmData'
// import { classesAdimuraiData } from './ClassesAdimuraiData'
// import { classesParaiData } from './ClassesParaiData'
import RightArrow from '../img/rightArrow.png'

const Classes = () => {
  return (
    <div className="Classes" id="classes">
    {/* silambam */}
    <div className="classes-header">
        <span className='stroke-text'>Explore Our</span>
        <span>Classes</span>
        <span style={{"font-size":"1.3rem"}} className='stroke-text'>Silambam-Adimurai</span>
    </div>
    <div className="classes-categories">
        {classesSlmbmData.map((classes)=>
           <div className="category">
            {classes.image}
            <span>{classes.heading}</span>
            <span>{classes.details}</span>
            {/* <div className="join-now">
              <span>Join Now</span>
              <img src={RightArrow} alt="" />
            </div> */}
    </div>
        )}
    </div>
</div>
  )
}

export default Classes