import image1 from "../img/bars.png";
import image2 from "../img/hero_image.png";
import image3 from "../img/vinoth-text.png";
import vino from "../img/hero_image.png";
import velkambu from "../img/velkambupic.JPG"
import valpic from "../img/valpic.JPG"
import ek from "../img/erattaikambu.JPG"
import kuthuvarisai from "../img/kuthuvarisai.JPG"
import silambamInd from "../img/silambamIndia.JPG"
import kuthuvarisai2 from "../img/kuthuvarisai2.JPG"
import surul1 from "../img/surul1.JPG"
import errataisurul from "../img/errataisurul.JPG"

export const GallaryData = [
  {
    image: velkambu,
  },
  {
    image: ek,
  },
  {
    image : valpic,
  },
  {
    image : surul1,
  } ,
  {
    image : kuthuvarisai,
  },
  {
    image : errataisurul,
  } ,
  {
    image : vino,
  }
];
